<template>
  <el-main>
    <router-link to="/store/addStoreUsers">
        <el-button type="primary" size="small" icon="el-icon-plus">添加店员账号</el-button>
    </router-link>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#f8f9fa' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="trading_area" label="店员信息" width="220" align="center">
        <template slot-scope="scope">
            <el-image :src="scope.row.staff_avatar" style="width:40px;height:40px;border-radius:50%;"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="staff_name" label="店员姓名" align="center"></el-table-column>
      <el-table-column prop="staff_phone" label="手机号码" align="center"></el-table-column>
      <el-table-column prop="staff_account" label="登录账号" align="center"></el-table-column>
      <el-table-column prop="state" label="是否禁用" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.state" @change="upStatus(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="remove(scope.row.id, scope.$index)" :disabled="scope.row.is_default == 1 ? true : false" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging class="paging" :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue'
export default {
  components: { Paging },
  data() {
    return {
      list: [],
      page: 1,
      rows: 10,
      total_number: 0,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.$axios
        .post(this.$api.store.storeUsersList, {
          page: this.page,
          rows: this.rows,
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list
            for (let i in list) list[i].state = list[i].state ? !0 : !1
            this.list = list
            this.total_number = res.result.total_number
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    edit(row) {
      this.$router.push({
        path: '/store/editClerk',
        query: {
          info: JSON.stringify(row)
        }
      })
    },
    //改变状态
    upStatus(row) {
      row.state  = row.state ? 1 : 0;
      this.$axios.post(this.$api.store.editStoreUsers, row).then((res) => {
        if (res.code == 0) {
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    remove(id, index) {
      this.$confirm('确认删除此账号？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .post(this.$api.store.delStoreUsers, {
            id: id
          })
          .then((res) => {
            if (res.code == 0) {
              this.list.splice(index, 1)
              this.$message({
                message: '删除成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
        this.getList()
      } else {
        this.page = val
        this.getList()
      }
    },
    cancelSearch() {
      this.trading_area = ''
      this.status = 0
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  font-size: 14px;
  background: #fff;
  display: flex;
  flex-direction: column;
  .el-table {
    flex: 1;
    margin-top:15px;
  }
}
</style>
